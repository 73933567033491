import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LiveProjectData = ({ projectId }) => {

    const [liveData, setLiveData] = useState({}); // State to hold live data

    const fetchLiveData = async () => {
        try {
            const response = await axios.get(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`);
            if (response.status === 200) {
                console.log(response.data)

                console.log(response.data.liveUsers);
                setLiveData(response.data); // Store the live data in state
            }
        } catch (error) {
            console.error('Error fetching live data:', error);
        }
    };

    useEffect(() => {
        fetchLiveData(); // Initial fetch
        const intervalId = setInterval(fetchLiveData, 5000); // Fetch every 5 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [projectId]);

    return (
        <tr>
            <td>{liveData.totalDuration}</td>
            <td>{liveData.liveUsers}</td>
            <td>{liveData.usersInQueue}</td>
        </tr>
    );
};

export default LiveProjectData;
