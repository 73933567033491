import React, { useState } from 'react';
import { Button, Container, Stack } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import MicrosoftLogin from "react-microsoft-login";
import { useDiscordLogin } from 'react-discord-login';
import { IoLogoDiscord } from "react-icons/io5";
import { BiLogoMicrosoft } from "react-icons/bi";

const GoogleLoginComponent = ({ onLogin }) => {
  const navigate = useNavigate();
const [loading,setLoading] = useState(false);

  const handleSuccess = async (codeResponse) => {
setLoading(true);

    const { code } = codeResponse; // Extract the authorization code
    console.log(codeResponse);

    try {
      // Exchange authorization code for access and ID tokens
      const tokenResponse = await axios.post('https://oauth2.googleapis.com/token', {
        code,
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
        redirect_uri: window.location.origin,
        grant_type: 'authorization_code',
      });

      const { id_token } = tokenResponse.data;
      sessionStorage.setItem('userToken', id_token);

      const userRes = await axios.get(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${id_token}`);
      const user = userRes.data;

      const { email, name, picture: photo, sub: googleId } = user;
      const response = await axios.post('https://api.streampixel.io/pixelStripeApi/users', { email, name, photo, googleId });
      onLogin(response.data);
      sessionStorage.setItem('userId', response.data._id);

      if (response.data.customerId == null) {
        navigate('/account');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: (error) => console.error('Login Failed:', error),
    flow: 'auth-code', // Specify the authorization code flow
  });


  const authHandler = async(err, data) => {


    console.log("username:",data.displayName,"token:",data.accessToken,"mail:",data.mail,"id:",data.id)
    console.log(err, data);
    if(data.accessToken){
    sessionStorage.setItem('userToken', data.accessToken);
    const responseNew = await axios.post('https://api.streampixel.io/pixelStripeApi/users', {  
      email:data.mail,
      name:data.displayName,
      picture: data.avatar,
      googleId: data.id});

      onLogin(responseNew.data);
    sessionStorage.setItem('userId', responseNew.data._id);

    if (responseNew.data.customerId == null) {
     navigate('/account');
    } else {
      navigate('/dashboard');
    }
  
  }

  };


  const discordLoginParams = {
    clientId: '1261505947365408818',
    redirectUri: 'https://dashboard.streampixel.io',
    responseType: 'token', // or 'code'
    scopes: ['identify', 'email'],
    onSuccess:async (response) => {
 console.log(response);
        sessionStorage.setItem('userToken', response.access_token);
        
      const user = response.user;

      const responseNew = await axios.post('https://api.streampixel.io/pixelStripeApi/users', {  email:user.email,
        name:user.username,
        picture: user.avatar,
        googleId: user.id});

        onLogin(responseNew.data);

      sessionStorage.setItem('userId', responseNew.data._id);

      if (responseNew.data.customerId == null) {
        navigate('/account');
      } else {
        navigate('/dashboard');
      }

    },
    onFailure: error => {
        // Handle login failure
        console.error('Login failed:', error);
    },
};

const { buildUrl, isLoading } = useDiscordLogin(discordLoginParams);
  return (
    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '18%' }}>


    
{loading == true ? (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        <p style={{ marginTop: '10px' }}>Loading...</p>
    </div>
):(

<Stack gap={3} style={{maxWidth:"400px"}}>
<Button size='lg' onClick={() => login()} style={{background:"#444444", border:'1px solid #444444'}}>
        <FcGoogle size={'32px'} style={{ marginRight: '25px' }} />Continue with Google &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
      </Button>


<MicrosoftLogin  style={{maxWidth:"400px"}} withUserData={true} children={

<Button size='lg' style={{background:"#444444", border:'1px solid #444444',width:"400px"}}>
  <Stack direction='horizontal' gap="3">
<BiLogoMicrosoft size={'32px'} style={{marginLeft:"22px"}}/>
&nbsp;&nbsp; Continue with Microsoft &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
 </Stack>
</Button>

} clientId={"631f6750-f5fe-41dd-9382-df21f6cb8cc5"} redirectUri='https://dashboard.streampixel.io' authCallback={authHandler} />


<Button size='lg' onClick={() => (window.location.href = buildUrl())} disabled={isLoading} style={{background:"#444444", border:'1px solid #444444'}}>
        <IoLogoDiscord size={'32px'} style={{ marginRight: '25px' }} /> Continue with Discord &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
      </Button>


            </Stack>


)}
    </Container>
  );
};

export default GoogleLoginComponent;
