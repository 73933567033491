import React, { useEffect, useState,useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ProgressBar from '@ramonak/react-progress-bar';
import { Container, Row, Col,Modal, Form, Button, Alert, Table,Toast, InputGroup,ToastContainer, Stack } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsCloudUploadFill, BsCopy } from 'react-icons/bs';
import AWS from 'aws-sdk';
import moment from "moment";
import { useNavigate } from 'react-router-dom';

import { FaArrowLeft } from 'react-icons/fa';
import LiveProjectData from './liveProjectData';

const s3FileUpload = new AWS.S3({
    correctClockSkew: true,
    endpoint: 'fra1.digitaloceanspaces.com',
    accessKeyId: process.env.REACT_APP_ACCES_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: 'fra1',
    logger: console
});

const ProjectDetail = ({ user }) => {
    const [loading, setLoading] = useState(true);
    const [projectUpgrade, setProjectUpgrade] = useState(false);
    const [passwordProtection, setPasswordProtection] = useState(false); 
    const [password, setPassword] = useState('');
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState();
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progress, setProgress] = useState(0);
    const [alertMessage, setAlertMessage] = useState();
    const [alertVarient, setAlertVarient] = useState();
    const [showMessage, setShowMessage] = useState(false);
    const [fileName, setFileName] = useState('');
    const [isDragOver, setIsDragOver] = useState(false);
    const [newProjectName, setNewProjectName] = useState('');
    const [status, setStatus] = useState(false);
    const [showBtn, setShowBtn] = useState(true);
    const [showBtnVolume, setShowBtnVolume] = useState(true);

    const [micEnable, setMicEnable] = useState(false);
    const [mouseInput, setMouseInput] = useState(true);
    const [keyBoardInput, setKeyBoardInput] = useState(true);
    const [touchInput, setTouchInput] = useState(true);
    const [gamepadInput, setGamepadInput] = useState(false);
    const [xrInput, setXrInput] = useState(false);
    const [resolution, setResolution] = useState(true);
    const [hoverMouse, setHoverMouse] = useState(true);
    const [maxRunTime, setMaxRunTime] = useState();
    const [afktimeout, setAfktimeout] = useState();

    const [showModal, setShowModal] = useState(false);
    const rzpRef = useRef();

    const navigate = useNavigate();

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

      useEffect( ()=>
        {
        if (user.customerId == null) {
            navigate('/account');
          } 
       },[user])
    

      useEffect(() => {
        let isMounted = true;
    
        const fetchProjectDetails = async () => {
            try {
                const response = await axios.get(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`);
                if (response.status === 200) {
                    setProjectData(response.data);
                    setNewProjectName(response.data.name);
                    setStatus(response.data.status);
                    setShowBtn(response.data.showBtn);
                    setShowBtnVolume(response.data.showBtnVolume);
                    setMicEnable(response.data.showMic);
                    setPasswordProtection(response.data.passwordProtect);
                    setPassword(response.data.password);
                    setAfktimeout(response.data.afktimeout);
                    setMaxRunTime(response.data.maxRunTime);
                    setMouseInput(response.data.mouseInput);
                    setTouchInput(response.data.touchInput);
                    setKeyBoardInput(response.data.keyBoardInput);
                    setResolution(response.data.resolution);
                    setHoverMouse(response.data.hoverMouse);
                    setXrInput(response.data.xrInput);

                    setGamepadInput(response.data.gamepadInput);


                    if ( response.data.subscriptionStatus == "cancelled" || response.data.subscriptionStatus == "pending") {
                        setLoading(false);
                        // Stop fetching details if subscriptionStatus is not 'created'
                        return;
                    }
    
                    if ( response.data.subscriptionStatus == "created" && response.data.paymentMethodAttached == false) {
                        setLoading(false);
                        // Stop fetching details if subscriptionStatus is not 'created'
                        return;
                    }

                    if ( response.data.subscriptionStatus == "active" || response.data.subscriptionStatus == "authenticated" || response.data.subscriptionStatus == "freeTrial" || response.data.subscriptionStatus == "freeTrialEnd") {
                        setLoading(false);
                        // Stop fetching details if subscriptionStatus is not 'created'
                        return;
                    }

                }
            } catch (error) {
                console.error('Error fetching project details:', error);
            }
    
            // Continue polling every 30 seconds if component is still mounted
            if (isMounted) {
                await delay(1500); // 30 seconds delay
                fetchProjectDetails();
            }
        };
    
        fetchProjectDetails();
    
        return () => {
            isMounted = false; // Cleanup to prevent memory leaks
        };
    }, [projectId]);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const file = e.dataTransfer.files[0];
        handleFileUpload(file);
    };

    const handleFileUpload = async (file) => {

        if(projectData.subscriptionStatus == 'active' || projectData.subscriptionStatus == "authenticated"){
       
        if (file.size > 26843545600) {
            setShowMessage(true);
            setAlertMessage('Invalid file size');
            setAlertVarient('danger');

        } else {
            const extArray = ['.zip'];
            const fileValid = extArray.some(el => file.name.includes(el));
            if (fileValid) {
                setShowProgressBar(true);
                const currDate = Date.now();
                let newName = currDate + file.name.replace(/\s/g, "");
                setFileName(newName.replace(".zip", ""));
                const newFile = new File([file], newName);

                const uploadRequest = new AWS.S3.ManagedUpload({
                    params: { 
                         Bucket:'unreal-engine-sdk',
                        Key:newName, 
                        Body:newFile, 
                        ACL: 'public-read',
                        partSize:  200 * 1024 * 1024,
                        queueSize: 1 }, 
                    service: s3FileUpload
                });
                uploadRequest.on("httpUploadProgress", function (evt) {
                    setProgress(parseInt((evt.loaded * 100) / evt.total));
                });
                uploadRequest.send(async function (err, response) {
                    if (err) {
                        console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
                    } else {
                        if (response.Location !== undefined && response.Location !== null) {
                            await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, { fileUrl: response.Location,
                                 webIp:process.env.REACT_APP_WEBIP,
                                userId: user._id });
                            window.location.reload(true);
                        }
                    }
                });

            } else {

                setShowMessage(true);
                setAlertMessage('Invalid file type');
                setAlertVarient('danger');

                
            }
        }
    }else{
        setShowMessage(true);
        setAlertMessage('Not Allowed');
        setAlertVarient('danger');        
    }
    };

    const cancelFileUpload = () => {
        setShowProgressBar(false);
        window.location.reload(false);
    };

    const copyLink = (link) => {
        console.log(link);
        navigator.clipboard.writeText(link);
        setShowMessage(true);
        setAlertMessage('Link successfully copied to clipboard');
        setAlertVarient('primary');

    };

    const extractNameFromUrl = (url) => {
        const parts = url.split("/");
        const fileName = parts[parts.length - 1];
        const fileName1 = fileName.replace(".zip", "");
        const fileName2 = fileName1.replace(/^\d+/, '');
        return fileName2;
    };

    const renderTableStream = () => {
         
        return  (
         <LiveProjectData projectId={projectId}/>
        );
    };

   

    const renderTableRows = () => {
        if (!projectData || !projectData.files || projectData.files.length === 0) {
            return (
                <tr>
                <td style={{ borderRight: 'none' }}></td>
                <td style={{ border: 'none', textAlign: 'end' }}>
                    <p>No Build Uploaded Yet</p>
                </td>
                <td style={{ border: 'none' }}></td>
                <td style={{ borderLeft: 'none' }}></td>
            </tr>

                
                
            ); // Return an empty array if projectData or projectData.files is null, undefined, or empty
        }
    
        // Sort files by date in descending order
        const sortedFiles = projectData.files.sort((a, b) => new Date(b.date) - new Date(a.date));
        
        // Find the latest active status
        let latestActiveStatus = null;
        for (const file of sortedFiles) {
            if (file.status === 'Approved') {
                latestActiveStatus = file;
                break;
            }
        }
    
        // Find the latest pending status with a date later than the latest active date
        let latestPendingStatus = null;
        for (const file of sortedFiles) {
            if (file.status !== 'Approved' && (!latestActiveStatus || new Date(file.date) > new Date(latestActiveStatus.date))) {
                latestPendingStatus = file;
                break;
            }
        }

    
        // Create table rows
        const tableRows = [];
        if (latestActiveStatus) {
            tableRows.push(
                <tr key={-1}>
                    <td style={{ borderRight: 'none' }}>{extractNameFromUrl(latestActiveStatus.url)}</td>
                    <td style={{ border: 'none' }}>Current live Build</td>
                    <td style={{ border: 'none' }}>{moment(latestActiveStatus.date).format("DD-MM-YYYY")}</td>
                    <td style={{ borderLeft: 'none' }}>{latestActiveStatus.objection}</td>
                </tr>
            );
        }
        if (latestPendingStatus) {
            tableRows.push(
                <tr key={0}>
                    <td style={{ borderRight: 'none' }}>{extractNameFromUrl(latestPendingStatus.url)}</td>
                    {latestPendingStatus.status == "Reject"?(
                    <td style={{ border: 'none' }}>Rejected</td>

                    ):(
                        <td style={{ border: 'none' }}>In review</td>

                    )}
                    <td style={{ border: 'none' }}>{moment(latestPendingStatus.date).format("DD-MM-YYYY")}</td>
                    <td style={{ borderLeft: 'none' }}>{latestPendingStatus.objection}</td>
                </tr>
            );
        }


    
        return tableRows;
    };
    
    

    const updateProjectName = async () => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                name: newProjectName,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Name Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };



    const updateProjectBtn = async (value) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                showBtn: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectBtnVolume = async (value) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                showBtnVolume: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectMic = async (value) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                showMic: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectMouseInput = async (value) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                mouseInput: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };


    const updateProjectKeyBoardInput = async (value) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                keyBoardInput: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };


    const updateProjectTouchInput = async (value) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                touchInput: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };


    const updateProjectGameInput = async (value) => {

        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                gamepadInput: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectXrInput = async (value) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                xrInput: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectMouseHover = async (value) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                hoverMouse: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectMaxtime = async () => {
if(maxRunTime > 1 && maxRunTime < 1440){
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                maxRunTime: maxRunTime, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    }
    else{
        setShowMessage(true);
        setAlertVarient('error')
        setAlertMessage('Max RunTime value must be in the range of 1 to 1440.');
    }
    };

    const updateProjectAfkime = async () => {
        if(afktimeout> 60 && afktimeout < 7200 ){
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                afktimeout: afktimeout, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    }else{
        setShowMessage(true);
        setAlertVarient('error')
        setAlertMessage('AFK Timeout value must be in the range of 60 to 7200.');
    }
    };

    const updateProjectResolution = async (value) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                resolution: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };
    
    const updateProjectStatus = async (statusValue) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                status: statusValue, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Status Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };


    const cancelSubscriptionModal = async()=>{
    setShowModal(true);

    }
    const cancelSubscription = async () => {
        console.log(projectData);
      try {
         
              const response = await axios.post('https://api.streampixel.io/pixelStripeApi/projects/cancelSub', {
                  subId: projectData.subId,
                  userId: user._id,
                  projectId:projectData._id,
                  webIp:process.env.REACT_APP_WEBIP
              });
              if (response.status === 200) {
                setShowModal(false);
                  setProjectData(response.data);
                  setShowMessage(true);
                  setAlertMessage('Subscription Cancelled ');
                 setAlertVarient('success');  
                 await delay(3000);
                 const responseNew = await axios.get(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`);
    
                if (responseNew.status === 200) {
                    setProjectData(response.data);
                    setNewProjectName(response.data.name);
                    setStatus(response.data.status);
                    window.location.reload(false);
                }
              }
          
      } catch (error) {
          console.error('Error cancelling subscription:', error);
      }
  };
  

  const reSubscribe = async () => {
    try {
        const currentDate = new Date();
        const endDate = new Date(projectData.subscriptionEndDate);
        if (currentDate < endDate) {
            // Update subscription status to 'active'
            const response = await axios.post('https://api.streampixel.io/pixelStripeApi/projects/resumeSub', {
                subId: projectData.subId,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP
            });
            if (response.status === 200) {
                setProjectData(response.data);
            }
        } else {
            // Handle case if the subscription has already expired
            console.log("Cannot re-subscribe: Subscription already expired");
            // You can show an alert or message here if needed
        }
    } catch (error) {
        console.error('Error re-subscribing:', error);
    }
};

const saveProjectName = async()=>{
    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        updateProjectName();
    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }


}

const handleStatus = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setStatus(event);
        updateProjectStatus(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}
const updateProjectPasswordStatus = async (passwordProtect)=>{

    try {
        const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
            passwordProtect: passwordProtect, // Include status in the request
            userId: user._id,
            webIp:process.env.REACT_APP_WEBIP

        });
        if (response.status === 200) {
            setProjectData(response.data);
            setShowMessage(true);
            setAlertVarient('success')
            setAlertMessage('Project Status Updated Successfully');
        }
    } catch (error) {
        console.error('Error updating project name:', error);
    }
}



const updateProjectPassword = async () => {
    try {
        const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
            password: password,
            userId: user._id,
            webIp:process.env.REACT_APP_WEBIP

        });
        if (response.status === 200) {
            setProjectData(response.data);
            setShowMessage(true);
            setAlertVarient('success')
            setAlertMessage('Password Updated Successfully');
        }
    } catch (error) {
        console.error('Error updating project name:', error);
    }
};

const handlePasswordProtectionToggle =async (event) => {

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setPasswordProtection(event);
        updateProjectPasswordStatus(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }

    setPasswordProtection(!passwordProtection);
};

const handleBtnShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setShowBtn(event);
        updateProjectBtn(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleBtnVolumeShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setShowBtnVolume(event);
        updateProjectBtnVolume(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleMicEnable = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setMicEnable(event);
        updateProjectMic(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}


const handleMouseInputShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setMouseInput(event);
        updateProjectMouseInput(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleKeyBoardInputShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setKeyBoardInput(event);
        updateProjectKeyBoardInput(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleTouchInputShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setTouchInput(event);
        updateProjectTouchInput(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleGamepadInputShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setGamepadInput(event);
        updateProjectGameInput(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleHoverMouse = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setHoverMouse(event);
        updateProjectMouseHover(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleXr = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setXrInput(event);
        updateProjectXrInput(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleMaxRunTime = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setMaxRunTime(event);
        updateProjectMaxtime(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleAfkTime = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setAfktimeout(event);
        updateProjectAfkime(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleResolution = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated') {
        setResolution(event);
        updateProjectResolution(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleBackClick = () => {
    navigate('/dashboard');
};


const generatePayLink = async()=>{

        try {
            setLoading(true); 

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID,
               order_id: projectData.orderId,

                name: 'Streampixel',
                description: 'Basic Streaming Plan',
                handler: async function (res) {

                    const resProject = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectData._id}`, {
                   
                        userId: user._id,
                        webIp:process.env.REACT_APP_WEBIP,
                        paymentMethodAttached:true,
                        subscriptionStatus:"paymentProcess"
                    
                });
                  window.location.reload(true);
        
                    
                },
                modal: {
                    ondismiss: async function () {
                    
                    }
                },
                prefill: {
                    name: user.name,
                    email: user.email,
                    contact: user.contact
                },
                theme: {
                    color: '#F37254'
                }
            };

            rzpRef.current = new window.Razorpay(options);
            rzpRef.current.open();

        } catch (error) {
            console.error('Error creating project:', error);
        } finally {
            setLoading(false); // Stop loading indicator
        }

}



const upgradeSubscription = async()=>{


    try {
        setLoading(true); // Start loading indicator
        setProjectUpgrade(true);
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID,
            subscription_id: projectData.subId,
            name: 'Streampixel',
            description: 'Basic Streaming Plan',
            handler: async function (res) {
                setLoading(true);

                const resProject = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectData._id}`, {
                   
                    userId: user._id,
                    webIp:process.env.REACT_APP_WEBIP,
                    paymentMethodAttached:true,
                    subscriptionStatus:"paymentProcess"
                
            });
            window.location.reload(true); 
            },
            modal: {
                ondismiss: async function () {


                    try {
                        const res = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectData._id}`, {
                           
                                userId: user._id,
                                webIp: process.env.REACT_APP_WEBIP,
                                paymentMethodAttached:false,
                                subStatus:"regular",
                           
                        });

                    window.location.reload(true);   
                    } catch (error) {
                        console.error('Error updating project:', error);
                    }
                }
            },
            prefill: {
                name: user.name,
                email: user.email,
                contact: user.contact
            },
            theme: {
                color: '#F37254'
            }
        };

        rzpRef.current = new window.Razorpay(options);
        rzpRef.current.open()


    } catch (error) {
        console.error('Error creating project:', error);
    } finally {

    
        setLoading(false); // Stop loading indicator
        setProjectUpgrade(false);
    }

}
    return (
        <Container>


{loading && (projectData && (projectData.subscriptionStatus === 'created' || projectData.subscriptionStatus === 'paymentProcess')) && (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        <p style={{ marginTop: '10px' }}>Your payment is being processed. This won't take long. <br /> Please do not close the page.</p>
    </div>
)}


<ToastContainer position={'bottom-end'}>
<Toast  bg={alertVarient} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
          <Toast.Header>
           
    
          </Toast.Header>
          <Toast.Body>{alertMessage}</Toast.Body>
        </Toast>
</ToastContainer>
{!loading && (
    <>


                     <Button  variant="primary" onClick={handleBackClick}>
                        <FaArrowLeft /> Back 
                    </Button>

                    <br/>
                    <br/>
                    <Row>
                    <Col md={2}>
            <h5 style={{marginTop:"12px"}}>Project Details:</h5>
             </Col>

             <Col md={{ span: 1, offset: 9}} >
            
             <Form.Group style={{marginTop:"-60px"}}>
                        <Form.Label>Status</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label={status ? 'Active' : 'Inactive'}
                            checked={status}
                            onChange={(e) => handleStatus(e.target.checked)}
                        />
                    </Form.Group>

             </Col>

            </Row>
        
            <Row className="mb-3">
                <Col md={4}>
                <Form.Group className="mb-3">
    <Form.Label>Project Name</Form.Label>
    <InputGroup>
        <Form.Control
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            placeholder="Enter project name"
        />
        <Button variant="primary" onClick={saveProjectName}>
            Save
        </Button>
    </InputGroup>
</Form.Group>
                </Col>
                <Col md={4}>
                {projectData && (
    <Form.Group >
        <Form.Label>Share Link</Form.Label>
        <>
            {!projectData.appName ? (
                <Form.Control value="Upload and await approval for share link." readOnly />
            ) : (<> 

                <InputGroup >
                <Form.Control value={`https://share.streampixel.io/${projectData._id}`} readOnly />

        <InputGroup.Text > 
        <BsCopy onClick={()=>copyLink(`https://share.streampixel.io/${projectData._id}`)}/>

        </InputGroup.Text>
      </InputGroup>

              
                </>
            )}
        </>
    </Form.Group>
)}
                </Col>


                <Col md = {4}>
                    <Form.Group>
                        <Form.Label>No. of concurrent streams available</Form.Label>
                        <Form.Control value={projectData ? projectData.allowedUsers : 2} readOnly />
                    </Form.Group>
                </Col>
               

                
            </Row>

            <h5>Build Details:</h5>

            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th style={{ borderRight: 'none' }}>Name</th>
                        <th style={{ border: 'none' }}>Status</th>
                        <th style={{ border: 'none' }}>Date Of Upload</th>
                        <th style={{ borderLeft: 'none' }}>Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </Table>
            <Form.Group className="mb-3">
    <Button variant="primary" onClick={() => document.getElementById('input-file').click()}>
        Upload New Build
    </Button>
    {showProgressBar && (
        <>
 <ProgressBar completed={progress} maxCompleted={100} bgColor="#26F8FF" />            
 <Button variant="danger" onClick={cancelFileUpload} className="mt-2">Cancel</Button>
        </>
    )}
    {progress === 100 && (
        <Alert variant="success" className="mt-3">File Uploaded Successfully</Alert>
    )}
    <Form.Control
        type="file"
        id="input-file"
        className="visually-hidden"
        onChange={(e) => handleFileUpload(e.target.files[0])}
    />
</Form.Group>

<h5>Streaming Details:</h5>

<Table striped bordered hover variant="dark">
    <thead>
        <tr>
            <th style={{ borderRight: 'none' }}>Total Number of Minutes Streamed</th>
            <th style={{ border: 'none' }}>Live Users</th>
            <th style={{ border: 'none' }}>Users in Queue</th>
        </tr>
    </thead>
    <tbody>
        {renderTableStream()}
    </tbody>
</Table>
            
           <h5>Password Setting:</h5>

           <Row>

          
           <Col md={{ span: 4}} >

 <Form.Group >
                <Form.Label >
                    Password Protect
                </Form.Label>

                <Form.Check
                type="switch"
                id="custom-switch"
                label={passwordProtection ? 'Enabled' : 'Disbaled'}
                checked={passwordProtection}
                onChange={(e) => handlePasswordProtectionToggle(e.target.checked)}
            />
              
            </Form.Group>
</Col>

<Col md={{ span: 4}} >

            {passwordProtection && (

<Form.Group >
<Form.Label>Password</Form.Label>
<InputGroup>
    <Form.Control
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter your password"
    />
    <Button variant="primary" onClick={updateProjectPassword}>
        Save
    </Button>
</InputGroup>
</Form.Group>

        
            )}
            </Col>

           </Row>


            <h5 style={{marginTop:"32px"}}>Frontend Settings:</h5>

<Row>

 <Col md={{ span: 2}} >

 <Form.Group >
            <Form.Label>Show Fullscreen Button</Form.Label>
            <Form.Check
                type="switch"
                id="custom-switch"
                label={showBtn ? 'show' : 'hide'}
                checked={showBtn}
                onChange={(e) => handleBtnShow(e.target.checked)}
            />
        </Form.Group>

 </Col>

  <Col md={{ span: 2}} >

 <Form.Group >
            <Form.Label>Show Volume Button</Form.Label>
            <Form.Check
                type="switch"
                id="custom-switch"
                label={showBtnVolume ? 'show' : 'hide'}
                checked={showBtnVolume}
                onChange={(e) => handleBtnVolumeShow(e.target.checked)}
            />
        </Form.Group>

 </Col>


 <Col md={{ span: 2}} >

<Form.Group >
           <Form.Label>Mic Setting</Form.Label>
           <Form.Check
               type="switch"
               id="custom-switch"
               label={micEnable ? 'Enabled' : 'Disbaled'}
               checked={micEnable}
               onChange={(e) => handleMicEnable(e.target.checked)}
           />
       </Form.Group>

</Col>

<Col md={{ span: 2}} >

<Form.Group >
           <Form.Label>Mouse Input Setting</Form.Label>
           <Form.Check
               type="switch"
               id="custom-switch"
               label={mouseInput ? 'Enabled' : 'Disbaled'}
               checked={mouseInput}
               onChange={(e) => handleMouseInputShow(e.target.checked)}
           />
       </Form.Group>

</Col>


<Col md={{ span: 2}} >

<Form.Group >
           <Form.Label>KeyBoard Input Setting</Form.Label>
           <Form.Check
               type="switch"
               id="custom-switch"
               label={keyBoardInput ? 'Enabled' : 'Disbaled'}
               checked={keyBoardInput}
               onChange={(e) => handleKeyBoardInputShow(e.target.checked)}
           />
       </Form.Group>

</Col>

<Col md={{ span: 2}} >

<Form.Group >
           <Form.Label>Touch Input Setting</Form.Label>
           <Form.Check
               type="switch"
               id="custom-switch"
               label={touchInput ? 'Enabled' : 'Disbaled'}
               checked={touchInput}
               onChange={(e) => handleTouchInputShow(e.target.checked)}
           />
       </Form.Group>

</Col>


<Col md={{ span: 2}} >

<Form.Group >
           <Form.Label>Gamepad Input Setting</Form.Label>
           <Form.Check
               type="switch"
               id="custom-switch"
               label={gamepadInput ? 'Enabled' : 'Disbaled'}
               checked={gamepadInput}
               onChange={(e) => handleGamepadInputShow(e.target.checked)}
           />
       </Form.Group>

</Col>




</Row>

<br/>
<Row>
<Col md={{ span: 2}} >

<Form.Group >
           <Form.Label>Xr Input Setting</Form.Label>
           <Form.Check
               type="switch"
               id="custom-switch"
               label={xrInput ? 'Enabled' : 'Disbaled'}
               checked={xrInput}
               onChange={(e) => handleXr(e.target.checked)}
           />
       </Form.Group>

</Col>

<Col md={{ span: 2}} >

<Form.Group >
           <Form.Label>Dynamic Resolution Setting</Form.Label>
           <Form.Check
               type="switch"
               id="custom-switch"
               label={resolution ? 'Enabled' : 'Disbaled'}
               checked={resolution}
               onChange={(e) => handleResolution(e.target.checked)}
           />
       </Form.Group>

</Col>

<Col md={{ span: 2}} >

<Form.Group >
           <Form.Label>Hovering Mouse Setting</Form.Label>
           <Form.Check
               type="switch"
               id="custom-switch"
               label={hoverMouse ? 'Enabled' : 'Disbaled'}
               checked={hoverMouse}
               onChange={(e) => handleHoverMouse(e.target.checked)}
           />
       </Form.Group>

</Col>



<Col md ={{span:3}}>
<Form.Group className="mb-3">
    <Form.Label>Max RunTime (Min. 1 Max 1440) </Form.Label>
    <InputGroup>
        <Form.Control
            value={maxRunTime}
            onChange={(e) => setMaxRunTime(e.target.value)}
            placeholder="Max runtime"
        />
        <Button variant="primary" onClick={updateProjectMaxtime}>
            Save
        </Button>
    </InputGroup>
</Form.Group>
</Col>


<Col md ={{span:3}}>
<Form.Group className="mb-3">
    <Form.Label>AFK Timeout </Form.Label>
    <InputGroup>
        <Form.Control
            value={afktimeout}
            onChange={(e) => setAfktimeout(e.target.value)}
            placeholder="AFK Timeout"
        />
        <Button variant="primary" onClick={updateProjectAfkime}>
            Save
        </Button>
    </InputGroup>
</Form.Group>
</Col>

</Row>

       
            
<br/>
            {projectData && (projectData.subscriptionStatus !== 'created') && (
              <>
            <h5>Subscription Details:</h5>
<>
{ projectData && projectData.subscriptionStatus == "freeTrialEnd" ?(

    <>
    <h6>
    Your free trial period has ended. Please upgrade your subscription to continue.
    </h6>
    </>
):


(

            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription Status</Form.Label>
                        {projectData && projectData.subscriptionStatus == "authenticated" ?(
     <Form.Control
     value="Free Trial"
 />
                        ):(
                            <Form.Control
                            value={projectData && projectData.subscriptionStatus}
                        />
                        )}
                   
                    </Form.Group>
                </Col>

                <>
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription Start Date</Form.Label>
                        
                        <Form.Control
                            value={moment(projectData && projectData.subscriptionStartDate).format("DD-MM-YYYY")}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription End Date</Form.Label>
                        <Form.Control
                            value={moment(projectData && projectData.subscriptionEndDate).format("DD-MM-YYYY")}
                        />
                    </Form.Group>
                </Col>
                </>
               
          
            </Row>

                    )}
                    </>
            </>
             )}
         
<br/>
                             
         {projectData.subscriptionStatus == "created" &&(
<>

<>
            <h5>Subscription Details:</h5>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription Status</Form.Label>
                        
                            <Form.Control
                            value={"Payment method not attached"}
                        />
                       
                   
                    </Form.Group>
                </Col>

                <>
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription Start Date</Form.Label>
                        
                        <Form.Control
                         value={"--/--/----"}

                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription End Date</Form.Label>
                        <Form.Control
                            value={"--/--/----"}
                        />
                    </Form.Group>
                </Col>
                </>
               
          
            </Row>
            </>
            <br/>
    {projectData.isFreeTrial ?(
        <>
        
 <Button
 variant="primary"
 onClick={() => window.open(projectData.subPayLink, '_blank', 'noopener,noreferrer')}
>
  Authenticate Card Details  
</Button>
<br/>
<br/>

<h6>Unable to authenticate your card from the button above. Use this <a href="#" onClick = {()=> generatePayLink()}>link</a> to manually authenticate your card.</h6>

</>
    ):(


        <>
        <Button
        variant="primary"
        onClick={() => window.open(projectData.subPayLink, '_blank', 'noopener,noreferrer')}
       >
         Retry Payment
       </Button>
       <br/>
       <br/>
       <h6>Unable to pay from the button above. Use this <a href="#" onClick = {()=> generatePayLink()}>link</a> to pay manually every month. </h6>
       
       </>
    )}
                  


  </>                  )}

                   


            {projectData && (projectData.subscriptionStatus === 'active') && (
                        <Button onClick={cancelSubscriptionModal}>Cancel Subscription</Button>
                    )}


{projectData && (projectData.subscriptionStatus === 'authenticated' || projectData && projectData.subscriptionStatus == "freeTrialEnd"  ) && (
  <>
  <Button onClick={upgradeSubscription}>Upgrade Subscription</Button>

                        <br/>
                        <br/>
{projectData && projectData.orderId &&(
                        <h6>Unable to pay from the button above. Use this <a href="#" onClick = {()=> generatePayLink()}>link</a> to pay manually every month. </h6>
)}
                        </>
                    )}
                  
                  <Modal 
                   centered
                  show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <h6>
                        <strong>Important : </strong>
                        Your susbscription will be cancelled immedialtely, and your project will no longer be streamed. No refunds will be issued
                        for the current month. This action is irreversible. Are you sure you want to continue?
                        </h6>
                    </div>
                        <br/>
                        <Row>
                            <Col>
                        <Button variant="primary" onClick={cancelSubscription}>
                            Continue
                        </Button>
</Col>
<Col>
                        <Button variant="primary" onClick={() => setShowModal(false)}>
                            Back
                        </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        
                </>
)}
        </Container>

    );
}

export default ProjectDetail;
