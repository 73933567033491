import React, { useEffect, useState } from 'react';
import { Container, Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import moment from 'moment';

const ProjectFiles = ({ user }) => {
    const [projectFiles, setProjectFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);
    const [showModalUser, setShowModalUser] = useState(false);

    const [modalProjectId, setModalProjectId] = useState(null);
    const [modalFileUrl, setModalFileUrl] = useState('');
    const [modalSubscriptionStatus, setModalSubscriptionStatus] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [modalMessageUser, setModalMessageUser] = useState('');
    const [appId, setAppId] = useState('');

    useEffect(() => {
        fetchProjectFiles();
    }, []);

    const fetchProjectFiles = async () => {
        try {
            const response = await fetch('https://api.streampixel.io/pixelStripeApi/projects');
            if (response.ok) {
                const data = await response.json();

                // Fetch user data for each project
                const updatedData = await Promise.all(data.map(async (project) => {
                    const userResponse = await fetch(`https://api.streampixel.io/pixelStripeApi/users/${project.user}`);
                    if (userResponse.ok) {
                        const userData = await userResponse.json();
                        project.userName = userData && userData.name;
                        project.userEmail = userData && userData.email;
                    }
                    project.files.sort((a, b) => new Date(b.date) - new Date(a.date));
                    project.reviewFileExists = project.files.some(file => file.status === 'pending');
                    return project;
                }));

                // Sort projects based on the latest file upload date
                updatedData.sort((a, b) => {
                    const latestFileA = new Date(a.files[0]?.date || 0);
                    const latestFileB = new Date(b.files[0]?.date || 0);
                    return latestFileB - latestFileA;
                });

                console.log(updatedData);
                setProjectFiles(updatedData);
            } else {
                console.error('Failed to fetch projects data');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    const getCurrentLiveBuildUrl = (files) => {
        const activeFiles = files.filter(file => file.status === 'Approved');
        if (activeFiles.length === 0) return '';
        activeFiles.sort((a, b) => new Date(b.date) - new Date(a.date));
        return activeFiles[0].url;
    };

    const getBuildInReviewUrl = (files) => {
        const pendingFiles = files.filter(file => file.status === 'pending');
        if (pendingFiles.length === 0) return '';
        pendingFiles.sort((a, b) => new Date(b.date) - new Date(a.date));
        return pendingFiles[0].url;
    };

    const handleApprove = (projectId, fileUrl, subscriptionStatus, appName) => {
        setModalProjectId(projectId);
        setModalFileUrl(fileUrl);
        setModalSubscriptionStatus(subscriptionStatus);
        setAppId(appName)
        setShowModalApprove(true);
    };

    const handleApproveModalSubmit = async () => {
        try {
            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${modalProjectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fileStatus: 'Approved',
                    fileUrl: modalFileUrl,
                    status: true,
                    appName:appId,
                    userId: user._id,
                    webIp: process.env.REACT_APP_WEBIP
                })
            });

            if (response.ok) {
                console.log('Project status updated successfully');
                fetchProjectFiles(); // Refresh the project list after update
            } else {
                console.error('Failed to update project status', response.statusText);
            }
        } catch (error) {
            console.error('Error updating project status:', error);
        }

        setShowModalApprove(false);
        setAppId('');
    };

    const handleObjection = (projectId, fileUrl) => {
        setModalProjectId(projectId);
        setModalFileUrl(fileUrl);
        setShowModal(true);
    };

    const handleModalSubmit = async () => {
        try {
            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${modalProjectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fileStatus: 'Reject',
                    fileUrl: modalFileUrl,
                    userId: user._id,
                    fileobjection: modalMessage,
                    webIp: process.env.REACT_APP_WEBIP
                })
            });

            if (response.ok) {
                console.log('Project status updated successfully');
                fetchProjectFiles(); // Refresh the project list after update
            } else {
                console.error('Failed to update project status', response.statusText);
            }
        } catch (error) {
            console.error('Error updating project status:', error);
        }

        setShowModal(false);
        setModalMessage('');
    };

    const handleUserUpdate = (projectId, noOfUser) => {
        setModalProjectId(projectId);
        setModalMessageUser(noOfUser);
        setShowModalUser(true);
    };

    const handleModalUserSubmit = async () => {
        try {
            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${modalProjectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    allowedUsers: modalMessageUser,
                    userId: user._id,
                    webIp: process.env.REACT_APP_WEBIP
                })
            });

            if (response.ok) {
                console.log('Project status updated successfully');
                fetchProjectFiles(); // Refresh the project list after update
            } else {
                console.error('Failed to update project status', response.statusText);
            }
        } catch (error) {
            console.error('Error updating project status:', error);
        }

        setShowModalUser(false);
        setModalMessageUser('');
    };

    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <h4>Project Files</h4>
                </Col>
                <Col md={{ span: 2, offset: 8 }}>
                    <div style={{ textAlign: 'end' }}>
                        <Button onClick={fetchProjectFiles} size="sm">Refresh Data</Button>
                    </div>
                </Col>
            </Row>

            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Project Id</th>
                        <th>Name</th>
                        <th>Use Name</th>
                        <th>Use Email</th>
                        <th>No. Of User</th>
                        <th>Subscription Status</th>
                        <th style={{ width: "50px" }}>Current Live Build</th>
                        <th style={{ width: "50px" }}>Build in Review</th>
                        <th>Action</th>
                        <th>Update</th>
                    </tr>
                </thead>
                <tbody>
                    {projectFiles.map(project => (
                        <tr key={project._id}>
                              <td>{project._id}</td>
                            <td>{project.name}</td>
                            <td>{project.userName}</td>
                            <td>{project.userEmail}</td>

                            <td>{project.allowedUsers}</td>
                            <td>{project.subscriptionStatus}</td>
                            <td style={{ width: "100px" }}>{getCurrentLiveBuildUrl(project.files)}</td>
                            <td style={{ width: "100px" }}>{getBuildInReviewUrl(project.files)}</td>
                            <td>
                                <Button size='sm' variant="primary"
                                    onClick={() => handleApprove(project._id, getBuildInReviewUrl(project.files), project.subscriptionStatus, project.appName)}
                                    disabled={!project.reviewFileExists}>
                                    {project.subscriptionStatus === "pending" ? (
                                        <> Approve & Raise Invoice </>
                                    ) : (
                                        <> Approve </>
                                    )}
                                </Button>
                                &nbsp; &nbsp; &nbsp;
                                <Button size='sm' variant="primary"
                                    onClick={() => handleObjection(project._id, getBuildInReviewUrl(project.files))}
                                    disabled={!project.reviewFileExists}>
                                    Objection
                                </Button>
                            </td>
                            <td>
                                <Button size='sm' variant="primary"
                                    onClick={() => handleUserUpdate(project._id, project.allowedUsers)}>
                                    Update Users
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Raise Objection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formObjectionMessage">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                value={modalMessage}
                                onChange={(e) => setModalMessage(e.target.value)} />
                        </Form.Group>
                        <br />
                        <Button variant="primary" onClick={handleModalSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showModalApprove} onHide={() => setShowModalApprove(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Approve Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formObjectionMessage">
                            <Form.Label>App Path</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                value={appId}
                                onChange={(e) => setAppId(e.target.value)} />
                        </Form.Group>
                        <br />
                        <Button variant="primary" onClick={handleApproveModalSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showModalUser} onHide={() => setShowModalUser(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update No Of users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formObjectionMessage">
                            <Form.Control rows={3}
                                value={modalMessageUser}
                                onChange={(e) => setModalMessageUser(e.target.value)} />
                        </Form.Group>
                        <br />
                        <Button variant="primary" onClick={handleModalUserSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </Container>
    );
};

export default ProjectFiles;
