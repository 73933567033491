import React, { useEffect, useState } from 'react';
import { Container, Button, Toast, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';

const AllUsers = ({ user }) => {
    const [users, setUsers] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        fetchAllUsers();
    }, []);

    const fetchAllUsers = async () => {
        try {
            const response = await fetch('https://api.streampixel.io/pixelStripeApi/users');
            if (response.ok) {
                const usersData = await response.json();
                setUsers(usersData);
            } else {
                console.error('Failed to fetch users data');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const deleteUser = async (userId) => {
        try {
            const response = await axios.delete(`https://api.streampixel.io/pixelStripeApi/users/${userId}`, {
                data: {
                    userId: user._id,
                    webIp: process.env.REACT_APP_WEBIP
                }
            });
            if (response.status === 204) {
                fetchAllUsers(); // Refresh the user list after deletion
                setAlertMessage('User deleted successfully.');
                setAlertVariant('success');
            } else {
                console.error('Failed to delete user');
                setAlertMessage('Failed to delete user.');
                setAlertVariant('danger');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            setAlertMessage('Error deleting user.');
            setAlertVariant('danger');
        }
        setShowMessage(true);
    };

    const handleTrialDoneChange = async (userId, newValue) => {

        console.log(userId);
        console.log(newValue);
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/users/${userId}`, {
                isTrialDone: newValue,
                userId: user._id,
                webIp: process.env.REACT_APP_WEBIP
            });
            if (response.status === 200) {
                fetchAllUsers(); // Refresh the user list after update
                setAlertMessage('User trial status updated successfully.');
                setAlertVariant('success');
            } else {
                console.error('Failed to update user trial status');
                setAlertMessage('Failed to update user trial status.');
                setAlertVariant('danger');
            }
        } catch (error) {
            console.error('Error updating user trial status:', error);
            setAlertMessage('Error updating user trial status.');
            setAlertVariant('danger');
        }
        setShowMessage(true);
    };

    return (
        <Container>
            <Toast bg={alertVariant} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
                <Toast.Body>{alertMessage}</Toast.Body>
            </Toast>
            <Row>
                <Col xs={2}><h4>All Users - {users.length} </h4></Col>
                <Col md={{ span: 2, offset: 8 }}>
                    <div style={{ textAlign: 'end' }}>
                        <Button onClick={fetchAllUsers}>Refresh Data</Button>
                    </div>
                </Col>
            </Row>
            <table className="table table-striped mt-3">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th>Found Us</th>
                        <th>Free Trial</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(userNew => (
                        <tr key={userNew._id}>
                            <td>{userNew.name}</td>
                            <td>{userNew.email}</td>
                            <td>{userNew.contact}</td>
                            <td>{userNew.foundUs}</td>
                            <td>
                                <Form.Check
                                    type="switch"
                                    id={`custom-switch-${userNew._id}`}
                                    label=""
                                    checked={userNew.trialDone}
                                    onChange={(e) => handleTrialDoneChange(userNew._id, e.target.checked)}
                                />
                            </td>
                            <td>
                                <Button
                                    variant="danger"
                                    onClick={() => deleteUser(userNew._id)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Container>
    );
};

export default AllUsers;
//6688a2afc3576aaa47a54253