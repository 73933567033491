import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Table,Row,Col } from 'react-bootstrap';
import moment from 'moment';

const FreeTrialProject = ({ user }) => {
    const [freeTrialProjects, setFreeTrialProjects] = useState([]);
    const [editedProject, setEditedProject] = useState({});
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        fetchFreeTrialProjects();
    }, []);

    const fetchFreeTrialProjects = async () => {
        try {
            const response = await fetch('https://api.streampixel.io/pixelStripeApi/projects');
            if (response.ok) {
                const data = await response.json();
                const newData = data.filter(e => e.subscriptionStatus === "authenticated");
                setFreeTrialProjects(newData);
            } else {
                console.error('Failed to fetch free trial projects data');
            }
        } catch (error) {
            console.error('Error fetching free trial projects:', error);
        }
    };

    const handleEdit = (project) => {
        setEditedProject({ ...project });
        setEditMode(true);
    };

    const handleSave = async (projectId) => {
        try {
            const startDate = editedProject.subscriptionStartDate;
            const endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + 2);

            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    subscriptionStartDate: startDate,
                    subscriptionEndDate: endDate,
                    status: editedProject.status,
                    userId: user._id,
                    webIp: process.env.REACT_APP_WEBIP,
                })
            });
            if (response.ok) {
                setEditMode(false);
                setEditedProject({});
                fetchFreeTrialProjects();
                alert('Success ');


            } else {
                console.error('Failed to update project');
                alert('Failed to update project')
            }
        } catch (error) {
            console.error('Error updating project:', error);
            alert('Error:'+error);
        }
    };


    const handleEndFreeTrial = async (projectId)=>{
        try {

            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    subscriptionStatus:"freeTrialEnd",
                    userId: user._id,
                    webIp: process.env.REACT_APP_WEBIP,
                })
            });
            if (response.ok) {
                setEditMode(false);
                setEditedProject({});
                fetchFreeTrialProjects();
                alert('Subscription End');
            } else {
                console.error('Failed to update project');
                alert('Failed to update project');
            }
        } catch (error) {
            console.error('Error updating project:', error);
            alert('Error:'+error);
        }
    }
    const handleStartSubscription =async(projectId)=>{

        try {
            const startDate = moment(); // Today's date
            let endDate;
            
            if (startDate.date() === 1) {
                // If it's the first day of the month, set the endDate to the last day of the previous month
                endDate = startDate.clone().subtract(1, 'days').endOf('month');
            } else {
                // Otherwise, set the endDate to one month later and one day before the current day
                endDate = startDate.clone().add(1, 'month').subtract(1, 'days');
            }
            

            

            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    subscriptionStartDate: startDate,
                    subscriptionEndDate: endDate,
                    subscriptionStatus:"active",
                    status: true,
                    userId: user._id,
                    webIp: process.env.REACT_APP_WEBIP,
                })
            });
            if (response.ok) {
                setEditMode(false);
                setEditedProject({});
                fetchFreeTrialProjects();
                alert('Success Subscription Started');
            } else {
                console.error('Failed to update project');
                alert('Failed to update project');
            }
        } catch (error) {
            console.error('Error updating project:', error);
            alert('Error:'+error);
        }

    }
    const renderTableRows = () => {
        return freeTrialProjects.map(project => (
            <tr key={project._id}>
                  <td>{project._id}</td>
                <td>{project.name}</td>
                <td>{project.user}</td>
                <td>
                    {editMode && editedProject._id === project._id ? (
                        <Form.Control
                            type="date"
                            value={editedProject.subscriptionStartDate}
                            onChange={(e) => setEditedProject({ ...editedProject, subscriptionStartDate: e.target.value })}
                        />
                    ) : (
                        project.subscriptionStartDate
                    )}
                </td>
                <td>{project.subscriptionEndDate}</td>
                <td>
                    {editMode && editedProject._id === project._id ? (
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label={editedProject.status ? 'Active' : 'Inactive'}
                            checked={editedProject.status}
                            onChange={(e) => setEditedProject({ ...editedProject, status: e.target.checked })}
                        />
                    ) : (
                        project.status ? 'Active' : 'Inactive'
                    )}
                </td>
                <td>
                    {editMode && editedProject._id === project._id ? (
                        <Button variant="primary" onClick={() => handleSave(project._id)}>Save</Button>
                    ) : (
                        <Button variant="primary" onClick={() => handleEdit(project)}>Edit</Button>
                    )}

                     &nbsp; &nbsp;
                     <Button variant="primary" onClick={() => handleStartSubscription(project._id)}>Start Subscription</Button>

                     &nbsp; &nbsp;
                     <Button variant="primary" onClick={() => handleEndFreeTrial(project._id)}>End FreeTrial</Button>


                </td>
            </tr>
        ));
    };

    return (
        <Container>

<Row>
    <Col xs={3}>            
    
    <h4>On Going Free Trial Projects</h4>

    </Col>
            
    <Col md={{ span: 2, offset: 7, }}>
    <div style={{textAlign:'end'}}>
    <Button  onClick={fetchFreeTrialProjects}>Refresh Data</Button>
</div>
</Col>
            
    </Row>

            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>ProjectId</th>
                        <th>Name</th>
                        <th>User</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </Table>
        </Container>
    );
};

export default FreeTrialProject;
