import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Container, Button,Toast, Row, Col } from 'react-bootstrap';
import moment from 'moment';

const AllProjectManual = ({ user }) => {
    const [allProjects, setAllProjects] = useState([]);
    const [alertMessage, setAlertMessage] = useState();
    const [alertVarient, setAlertVarient] = useState();
    const [showMessage, setShowMessage] = useState(false);
    useEffect(() => {
        fetchAllProjects();
    }, []);

    const fetchAllProjects = async () => {
        try {
            const response = await fetch('https://api.streampixel.io/pixelStripeApi/projects');
            if (response.ok) {
                const projectsData = await response.json();
                const projectsWithUserInfo = await Promise.all(projectsData.map(async project => {
                    const userResponse = await fetch(`https://api.streampixel.io/pixelStripeApi/users/${project.user}`);
                    if (userResponse.ok) {
                        const userData = await userResponse.json();
                        return {
                            ...project,
                            userEmail: userData && userData.email,
                            projectId:project._id,
                            shareId: project.shareId || '',  // Initialize shareId if undefined
                            status: project.status ,  // Initialize status if undefined
                            subscriptionStatus: project.subscriptionStatus || '',  // Initialize subscriptionStatus if undefined
                            totalAmount: project.totalAmount || 0,  // Initialize totalAmount if undefined
                            subscriptionStartDate:project.subscriptionStartDate ||  '',
                            subscriptionEndDate:project.subscriptionEndDate ||  '',

                        };
                    } else {
                        return project;
                    }

                    
                }));

                const newData = projectsWithUserInfo.filter(e => e.orderId !== undefined && e.subscriptionStatus !== "created" ).sort((a,b) => moment(a.subscriptionEndDate)-moment(b.subscriptionEndDate));

                setAllProjects(newData);
            } else {
                console.error('Failed to fetch projects data');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };





    const saveProject = async (project) => {

        project.userId = user._id;
        project.webIp = process.env.REACT_APP_WEBIP;
        
        try {
            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${project._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(project) // Send the whole project object
            });

            if(response.ok){
                fetchAllProjects();
            }
            if (!response.ok) {
                console.error('Failed to save project:', project);
            }
        } catch (error) {
            console.error('Error saving project:', project, error);
        }
    };

    const columns = [
        { dataField: '_id',text: 'Project ID' },
        { dataField: 'name', text: 'Name', editable: false },
        { dataField: 'userEmail', text: 'User Email', editable: false },
        { dataField: 'allowedUsers', text: 'No.Of Users' },
        {
            dataField: 'subscriptionStartDate',
            text: 'Start Date',
            formatter: (cell) => {
                if (!cell) {
                    return ''; // Return blank if cell value is null or undefined
                }
    
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
            },
            editor: {
                type: Type.DATE
            }
        },
        {
            dataField: 'subscriptionEndDate',
            text: 'End Date',
            formatter: (cell) => {
                if (!cell) {
                    return ''; // Return blank if cell value is null or undefined
                }
    
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
            },
            editor: {
                type: Type.DATE
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cell) => (cell ? 'Active' : 'Inactive'),
            editor: {
                type: Type.SELECT,
                options: [
                    { value: true, label: 'Active' },
                    { value: false, label: 'Inactive' }
                ]
            }
        },
        { dataField: 'shareId', text: 'Share Id' },
        { dataField: 'subscriptionStatus', text: 'Subscription Status' },



    ];


    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        afterSaveCell: (oldValue, newValue, row, column) => {
            // Handle saving cell changes here if needed
            console.log(`Cell ${column.text} updated for project ${row.name}: ${oldValue} -> ${newValue}`);
            if (column.text !== 'Raise Invoice') {
                // Update the status field before saving
                if (column.text === 'Status') {
                    let statusBool = newValue === "true";


                    console.log(statusBool);
                    row.status = statusBool;
                    console.log(row);
                }
                saveProject(row);
            }
        }
    });
/*
    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        afterSaveCell: (oldValue, newValue, row, column) => {

          
            // Handle saving cell changes here if needed
            console.log(`Cell ${column.text} updated for project ${row.name}: ${oldValue} -> ${newValue}`);
  if(column.text !== 'Raise Invoice'){
            saveProject(row);
        }
        }
    });
*/
    return (
        <Container>

<Toast bg={alertVarient} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
          <Toast.Header>
           
    
          </Toast.Header>
          <Toast.Body>{alertMessage}</Toast.Body>
        </Toast>
<Row>
    <Col xs={3}><h4>Mannual Billing Projects</h4></Col>
            
            
    <Col md={{ span: 2, offset: 7, }}>
    <div style={{textAlign:'end'}}>
    <Button  onClick={fetchAllProjects}>Refresh Data</Button>
</div>
</Col>
            
    </Row>
            <BootstrapTable
            
                keyField="_id"
                data={allProjects.map(project => ({
                    ...project,
                    raiseInvoice: '...Loading' 
                }))}                columns={columns}
                cellEdit={cellEdit}
            />
        </Container>
    );
};

export default AllProjectManual;
